import React from "react";
import {FormattedMessage} from "react-intl";
import sanitize from "sanitize-html";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "react-contenteditable";

interface Props {
    availability: string | null;
    setAvailability: React.Dispatch<React.SetStateAction<string | null>>;
    education: string[] | null;
    setEducation: React.Dispatch<React.SetStateAction<string[] | null>>;
}

const AvailabilityAndEducation = ({availability, education, setAvailability, setEducation}: Props) => {
    const OnChange = (field: string) => (evt: { currentTarget: { innerHTML: any; }; }) => {
        const sanitizeConf: sanitize.IOptions = {
            allowedTags: ['ul', 'li', 'ol', 'br'],
            disallowedTagsMode: 'discard' as sanitize.DisallowedTagsModes
        };

        const newContent = sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf);

        switch (field) {
            case "availability":
                setAvailability(newContent);
                break;
            case "education":
                const sanitize = newContent.split('<li>').map((e) => e.replace('</li>', '')).filter((e) => e.length > 0);
                setEducation(sanitize);
                break;
        }

    };

    const educationMapped = education ? education.map((e, idx) => `<li key=${idx}>${e}</li>`).join('') : '';

    return <div className="mt-10">
        <div className="flex space-x-10">
            <div>
                <h3 className="text-xl font-medium mb-2"><FormattedMessage id={"availability"}/></h3>
                <ContentEditable html={availability ?? ""}
                                 className="content-editable"
                                 suppressContentEditableWarning={true}
                                 onChange={OnChange('availability')}
                                 onBlur={OnChange('availability')}
                />
            </div>
            {education && education.length > 0 && (
                <div>
                    <h3 className="text-xl font-medium text-left mb-2"><FormattedMessage id={"education"}/></h3>
                    <ContentEditable
                        className="list-inside list-disc content-editable"
                        suppressContentEditableWarning={true}
                        html={educationMapped}
                        onChange={OnChange('education')}
                        tagName={'ul'}
                    />
                </div>
            )}
        </div>
    </div>;
};

export default AvailabilityAndEducation;
