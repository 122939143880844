import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {LogLevel, PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";
import LanguageSwitcher from "./components/LanguageSwitcher";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export const msalConfig = {
    auth: {
        clientId: "32d76184-8807-4026-872c-f6cf3e3c1d89",
        authority: "https://login.microsoftonline.com/d01b8f15-30b6-4cd3-8fb6-3cc874cf53ce",
        redirectUri: `${window.location.protocol}//${window.location.host}/dashboard`,
    },
    cache: {
        cacheLocation: "localStorage"
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

const client = new ApolloClient({
    uri: 'https://tuavgywjbraqhcgosr4mrph7ea.appsync-api.eu-west-1.amazonaws.com/graphql',
    headers: {
        "x-api-key": "da2-utfti4ycwrabjeedvb2opx46ie",
    },
    cache: new InMemoryCache(),
});

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <LanguageSwitcher>
                <BrowserRouter>
                    <ApolloProvider client={client}>
                        <Routes>
                            <Route path="/" element={<Navigate replace to="/login"/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/dashboard" element={<Dashboard/>}/>
                        </Routes>
                    </ApolloProvider>
                    <ToastContainer />
                </BrowserRouter>
            </LanguageSwitcher>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
