import React from "react";
import {FormattedMessage, useIntl} from "react-intl";

const AboutTuple = () => {

    const aboutDescription = useIntl().formatMessage({id: "about_tuple.description"});
    const ourMissionDescription = useIntl().formatMessage({id: "our_mission.description"});

    return <div>
        <h3 className="text-xl font-medium mb-2 pt-10"><FormattedMessage id={"about_tuple"}/></h3>
        <p className="-mb-4" dangerouslySetInnerHTML={{__html: aboutDescription}}></p>

        <h4 className="text-l font-medium mb-2 pt-10 text-gray-800"><FormattedMessage id={"our_mission"}/></h4>
        <p className="mb-4" dangerouslySetInnerHTML={{__html: ourMissionDescription}}></p>
    </div>;
};

export default AboutTuple;
