import ResumeEditor from "./ResumeEditor";
import React, {useCallback, useEffect, useState} from "react";
import {InteractionStatus} from "@azure/msal-browser";
import {useAccount, useIsAuthenticated, useMsal} from "@azure/msal-react";
import {useNavigate} from "react-router-dom";
import {
    Employee, Resume, useListEmployeesQuery
} from "./__generated__/graphql";
import EmployeesList from "./components/Dashboard/EmployeesList";
import ResumesList from "./components/Dashboard/ResumesList";

const Dashboard = () => {
    const isAuthenticated = useIsAuthenticated();
    const {inProgress, accounts, instance} = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();


    const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
    const [selectedResume, setSelectedResume] = useState<Resume | null>(null);

    const [accessToken, setAccessToken] = useState<string>("");
    const [employees, setEmployees] = useState<Employee[]>([]);

    const {data, loading, error, refetch: refetchListEmployees} = useListEmployeesQuery()

    const refreshToken = useCallback(() => {
        if (account) {
            instance.acquireTokenSilent({
                scopes: ["User.Read"],
                account: account
            }).then((response) => {
                if (response) {
                    setAccessToken(response.accessToken);
                }
            }).catch((e) => {
                instance.logout();
            });
        }
    }, [account, instance])

    useEffect(() => {
        const newEmployees = data?.listEmployees?.items as Employee[];

        setEmployees(newEmployees)

        if (selectedEmployee) {
            const newSelectedEmployee = newEmployees.find(e => e.id === selectedEmployee.id)
            if (newSelectedEmployee) {
                setSelectedEmployee(newSelectedEmployee)
            }

            if (selectedResume) {
                const resume = newSelectedEmployee?.resumes?.find(r => r?.id === selectedResume.id)
                if (resume) {
                    setSelectedResume(resume)
                }
            }
        }

    }, [data])

    useEffect(() => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            navigate('/login');
        }

        refreshToken();
    }, [account, instance, isAuthenticated, inProgress, navigate, refreshToken]);

    return (
        <>
            <div className="bg-gray-100 font-sans leading-normal tracking-normal flex min-h-screen">
                {employees && <EmployeesList employees={employees} selectedEmployee={selectedEmployee}
                                             setSelectedEmployee={setSelectedEmployee}
                                             setSelectedResume={setSelectedResume}
                                             refetchListEmployees={refetchListEmployees}/>}
                {selectedEmployee &&
                    <ResumesList resumes={selectedEmployee.resumes} setSelectedResume={setSelectedResume}
                                 selectedEmployee={selectedEmployee}
                                 selectedResume={selectedResume}
                                 refetchListEmployees={refetchListEmployees}/>}
                {selectedResume && selectedEmployee && (
                    <>
                        <ResumeEditor
                            employee={selectedEmployee}
                            resume={selectedResume}
                            refetchListEmployees={refetchListEmployees}
                        />
                    </>
                )}
            </div>
        </>
    )
}

export default Dashboard;
