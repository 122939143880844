import React, {useEffect} from 'react';
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {useNavigate} from "react-router-dom";
import {InteractionStatus} from "@azure/msal-browser";

const Login = () => {
    const isAuthenticated = useIsAuthenticated();
    const {instance, inProgress} = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None) {
            navigate('/dashboard');
        }
    })

    const redirectToLogIn = () => {
        instance.loginRedirect({
            scopes: ["User.Read"]
        }).catch((e) => {
            console.log(e);
        })
    }

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img className="mx-auto h-10 w-auto" src="logo.svg" alt="" />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to
                        your account</h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <div>
                        <button type="submit"
                                onClick={redirectToLogIn}
                                className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-app-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            Sign in using SSO
                        </button>
                    </div>
                </div>
            </div>
        </div>
);
}

export default Login;