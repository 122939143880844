import React from "react";
import './Overview.css';
import {FormattedMessage} from "react-intl";
import sanitize from "sanitize-html";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "react-contenteditable";

interface Props {
    primaryLanguages: string[] | null;
    setPrimaryLanguages: React.Dispatch<React.SetStateAction<string[] | null>>;
    otherLanguages: string[] | null;
    setOtherLanguages: React.Dispatch<React.SetStateAction<string[] | null>>;
    naturalLanguages: string[] | null;
    setNaturalLanguages: React.Dispatch<React.SetStateAction<string[] | null>>;
    technologies: string[] | null;
    setTechnologies: React.Dispatch<React.SetStateAction<string[] | null>>;
    tools: string[] | null;
    setTools: React.Dispatch<React.SetStateAction<string[] | null>>;
    cicd: string[] | null;
    setCicd: React.Dispatch<React.SetStateAction<string[] | null>>;
    cloud: string[] | null;
    setCloud: React.Dispatch<React.SetStateAction<string[] | null>>;
    monitoring: string[] | null;
    setMonitoring: React.Dispatch<React.SetStateAction<string[] | null>>;
    databases: string[] | null;
    setDatabases: React.Dispatch<React.SetStateAction<string[] | null>>;
}

const Overview = ({
                      primaryLanguages,
                      otherLanguages,
                      naturalLanguages,
                      technologies,
                      tools,
                      cicd,
                      cloud,
                      monitoring,
                      databases,
                      setPrimaryLanguages,
                      setOtherLanguages,
                      setNaturalLanguages,
                      setTechnologies,
                      setTools,
                      setCicd,
                      setCloud,
                      setMonitoring,
                      setDatabases
                  }: Props) => {

    const OnChange = (field: string) => (evt: { currentTarget: { innerHTML: any; }; }) => {
        const sanitizeConf: sanitize.IOptions = {
            allowedTags: [],
            disallowedTagsMode: 'discard' as sanitize.DisallowedTagsModes
        };

        const newContent = sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf);

        switch (field) {
            case "primaryLanguages":
                setPrimaryLanguages(newContent.split(", "));
                break;
            case "otherLanguages":
                setOtherLanguages(newContent.split(", "));
                break;
            case "naturalLanguages":
                setNaturalLanguages(newContent.split(", "));
                break;
            case "technologies":
                setTechnologies(newContent.split(", "));
                break;
            case "tools":
                setTools(newContent.split(", "));
                break;
            case "cicd":
                setCicd(newContent.split(", "));
                break;
            case "cloud":
                setCloud(newContent.split(", "));
                break;
            case "monitoring":
                setMonitoring(newContent.split(", "));
                break;
            case "databases":
                setDatabases(newContent.split(", "));
                break;
            default:
                break;
        }

    };

    return <div className="mt-10">
        <h3 className="text-xl font-medium mb-2"><FormattedMessage id={"overview.overview"}/></h3>
        <table>
            <tbody>
            {primaryLanguages && primaryLanguages.length > 0 && (
                <tr>
                    <td width="140"><FormattedMessage id={"overview.primary_languages"}/></td>
                    <td>
                        <i>
                            <ContentEditable html={primaryLanguages.join(", ")}
                                             className="content-editable"
                                             suppressContentEditableWarning={true}
                                             onChange={OnChange('primaryLanguages')}
                                             onBlur={OnChange('primaryLanguages')}
                            />
                        </i>
                    </td>
                </tr>
            )}
            {otherLanguages && otherLanguages.length > 0 && (
                <tr>
                    <td><FormattedMessage id={"overview.secondary_languages"}/></td>
                    <td>
                        <i>
                            <ContentEditable html={otherLanguages.join(", ")}
                                             className="content-editable"
                                             suppressContentEditableWarning={true}
                                             onChange={OnChange('otherLanguages')}
                                             onBlur={OnChange('otherLanguages')}
                            />
                        </i>
                    </td>
                </tr>
            )}
            {technologies && technologies.length > 0 && (
                <tr>
                    <td><FormattedMessage id={"overview.technologies"}/></td>
                    <td>
                        <i>
                            <ContentEditable html={technologies.join(", ")}
                                             className="content-editable"
                                             suppressContentEditableWarning={true}
                                             onChange={OnChange('technologies')}
                                             onBlur={OnChange('technologies')}
                            />
                        </i>
                    </td>
                </tr>
            )}
            {cicd && cicd.length > 0 && (
                <tr>
                    <td><FormattedMessage id={"overview.cicd"}/></td>
                    <td>
                        <i>
                            <ContentEditable html={cicd.join(", ")}
                                             className="content-editable"
                                             suppressContentEditableWarning={true}
                                             onChange={OnChange('cicd')}
                                             onBlur={OnChange('cicd')}
                            />
                        </i>
                    </td>
                </tr>
            )}
            {cloud && cloud.length > 0 && (
                <tr>
                    <td><FormattedMessage id={"overview.cloud"}/></td>
                    <td>
                        <i>
                            <ContentEditable html={cloud.join(", ")}
                                             className="content-editable"
                                             suppressContentEditableWarning={true}
                                             onChange={OnChange('cloud')}
                                             onBlur={OnChange('cloud')}
                            />
                        </i>
                    </td>
                </tr>
            )}
            {databases && databases.length > 0 && (
                <tr>
                    <td><FormattedMessage id={"overview.databases"}/></td>
                    <td>
                        <i>
                            <ContentEditable html={databases.join(", ")}
                                             className="content-editable"
                                             suppressContentEditableWarning={true}
                                             onChange={OnChange('databases')}
                                             onBlur={OnChange('databases')}
                            />
                        </i>
                    </td>
                </tr>
            )}
            {monitoring && monitoring.length > 0 && (
                <tr>
                    <td><FormattedMessage id={"overview.monitoring"}/></td>
                    <td>
                        <i>
                            <ContentEditable html={monitoring.join(", ")}
                                             className="content-editable"
                                             suppressContentEditableWarning={true}
                                             onChange={OnChange('monitoring')}
                                             onBlur={OnChange('monitoring')}
                            />
                        </i>
                    </td>
                </tr>
            )}
            {tools && tools.length > 0 && (
                <tr>
                    <td>Tools</td>
                    <td>
                        <i>
                            <ContentEditable html={tools.join(", ")}
                                             className="content-editable"
                                             suppressContentEditableWarning={true}
                                             onChange={OnChange('tools')}
                                             onBlur={OnChange('tools')}
                            />
                        </i>
                    </td>
                </tr>
            )}
            {naturalLanguages && naturalLanguages.length > 0 && (
                <tr>
                    <td><FormattedMessage id={"overview.natural_languages"}/></td>
                    <td>
                        <i>
                            <ContentEditable html={naturalLanguages.join(", ")}
                                             className="content-editable"
                                             suppressContentEditableWarning={true}
                                             onChange={OnChange('naturalLanguages')}
                                             onBlur={OnChange('naturalLanguages')}
                            />
                        </i>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    </div>;
};

export default Overview;
