import React from "react";
import {FormattedMessage} from "react-intl";
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import sanitize from "sanitize-html";

interface Props {
    description: string | null;
    update: React.Dispatch<React.SetStateAction<string | null>>
}

const AboutMe = ({description, update}: Props) => {
    const onContentChange = (evt: { currentTarget: { innerHTML: any; }; }) => {
        const sanitizeConf = {
            allowedTags: ["b", "i", "a", "p", "br", "ul", "li", "ol"],
            allowedAttributes: {a: ["href"]},
            disallowedTagsMode: 'discard' as sanitize.DisallowedTagsModes
        };

        const newContent = sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf);

        update(newContent);
    };

    return <div>
        <h3 className="text-xl font-medium mb-2"><FormattedMessage id={"about_me"}/></h3>
        <ContentEditable
            className="content-editable"
            suppressContentEditableWarning={true}
            onChange={onContentChange}
            onBlur={onContentChange}
            html={description ?? ""}/>
    </div>;
};

export default AboutMe;
