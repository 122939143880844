import React, {useRef} from "react";
import sanitizeHtml from "sanitize-html";
import sanitize from "sanitize-html";
import ContentEditable from "react-contenteditable";

interface Props {
    name: string | null;
    title: string | null;
    setTitle: React.Dispatch<React.SetStateAction<string | null>>;
    avatarSource: string | null;
    anonymized: boolean;
}

const Header = ({name, title, setTitle, avatarSource, anonymized}: Props) => {
    const onContentChange = (evt: { currentTarget: { innerHTML: any; }; }) => {
        const sanitizeConf = {
            allowedTags: [],
            disallowedTagsMode: 'discard' as sanitize.DisallowedTagsModes
        };

        const newContent = sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf);

        setTitle(newContent);
    };

    const titlePart = (
        <ContentEditable
            html={title ?? ""}
            className="content-editable"
            suppressContentEditableWarning={true}
            onChange={onContentChange}
            onBlur={onContentChange}
        />
    );

    return <div className="px-10 border-y border-app-gray-100">
        <div className="flex justify-between ">
            <div className="my-auto">
                {anonymized ? <>
                    <h1 className="font-medium text-2xl">
                        {titlePart}
                    </h1>
                </> : <>
                    <h1 className="font-medium text-2xl">{name}</h1>
                    <h2>
                        {titlePart}
                    </h2>
                </>}
            </div>
            <div className="h-28 relative">
                {!anonymized && (
                    <>
                        <img src={avatarSource ?? ""} alt={name ?? ""} className="py-2 h-full"/>
                    </>
                )}
            </div>
        </div>
    </div>;
};

export default Header;
