import React, {useRef, useState} from "react";
import {Employee, Resume, useCreateEmployeeMutation, useUpdateEmployeeMutation} from "../../__generated__/graphql";
import Modal from "../Modal";
import {v4 as uuidv4} from 'uuid';
import {toast} from "react-toastify";
import {MdEdit} from "react-icons/md";

interface EmployeesListProps {
    employees: Employee[],
    selectedEmployee: Employee | null,
    setSelectedEmployee: React.Dispatch<React.SetStateAction<Employee | null>>;
    setSelectedResume: React.Dispatch<React.SetStateAction<Resume | null>>;
    refetchListEmployees: () => void,
}

const EmployeesList = ({
                           employees,
                           selectedEmployee,
                           setSelectedEmployee,
                           setSelectedResume,
                           refetchListEmployees
                       }: EmployeesListProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
    const [employeeId, setEmployeeId] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [avatarSource, setAvatarSource] = useState<string>("");
    const [linkedIn, setLinkedIn] = useState<string>("");
    const [phone, setPhone] = useState<string>("");

    const [createEmployeeMutation, {loading}] = useCreateEmployeeMutation();
    const [updateEmployeeMutation, {loading: updateLoading}] = useUpdateEmployeeMutation();

    const addEmployee = () => {
        const afterSave = () => {
            refetchListEmployees();
            setName("");
            setEmail("");
            setLinkedIn("");
            setPhone("");
            setAvatarSource("");
            setEmployeeId("");
            setShowEmployeeModal(false);

            toast.success("Employee saved successfully.");
        }

        if (employeeId === "") {
            createEmployeeMutation({
                variables: {
                    input: {
                        fullName: name,
                        id: uuidv4(),
                        email: email,
                        linkedIn: linkedIn,
                        phone: phone,
                        avatarSource: avatarSource
                    }
                }
            }).then(afterSave);
        } else {
            updateEmployeeMutation({
                variables: {
                    input: {
                        id: employeeId,
                        fullName: name,
                        email: email,
                        linkedIn: linkedIn,
                        phone: phone,
                        avatarSource: avatarSource
                    }
                }
            }).then(afterSave)
        }
    }

    const imageToBas64AvatarSource = (event: any) => {
        const file = event.target.files[0];

        if (file && file.size > 300000) {
            toast.error("Avatar size should be less than 300kB.");
            return;
        }

        // Convert to base64 and set avatar source
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setAvatarSource(reader.result as string);
        };
        reader.onerror = function (error) {
            toast.error("Avatar upload failed. Please try again.");
        };
    };

    const inputChange = (inputName: string) => (e: { target: { name: string; value: any; }; }) => {
        switch (inputName) {
            case "name":
                setName(e.target.value);
                break;
            case "email":
                setEmail(e.target.value);
                break;
            case "linkedIn":
                setLinkedIn(e.target.value);
                break;
            case "phone":
                setPhone(e.target.value);
                break;
        }
    }

    const triggerAvatarInput = (event: any) => {
        hiddenFileInput.current?.click();
    };

    return (
        <>
            <Modal title={employeeId !== "" ? "Update employee" : "Add employee"} show={showEmployeeModal}
                   setShow={setShowEmployeeModal}
                   onSaved={addEmployee}
                   onClosed={() => {
                       setEmployeeId("");
                       setName("");
                       setEmail("");
                       setLinkedIn("");
                       setPhone("");
                       setAvatarSource("");
                   }}
            >
                <div className="flex flex-col space-y-4">
                    <div className="flex flex-col space-y-2 items-center">
                        <img src={avatarSource === "" ? "avatars/placeholder.png" : avatarSource} width={80}
                             height={80} className="cursor-pointer rounded-full"
                             onClick={triggerAvatarInput} alt=""/>
                        <div className="relative bottom-6 left-5">
                            <input
                                type="file"
                                onChange={imageToBas64AvatarSource}
                                ref={hiddenFileInput}
                                style={{display: 'none'}}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="name" className="text-sm font-medium text-gray-700">Full name</label>
                        <input type="text" name="name" id="name" value={name} onChange={inputChange("name")}
                               onBlur={inputChange("name")}
                               className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="email" className="text-sm font-medium text-gray-700">Email</label>
                        <input type="text" name="email" id="email" value={email} onChange={inputChange("email")}
                               onBlur={inputChange("email")}
                               className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="linkedIn" className="text-sm font-medium text-gray-700">LinkedIn</label>
                        <input type="text" name="linkedIn" id="linkedIn" value={linkedIn}
                               onChange={inputChange("linkedIn")}
                               onBlur={inputChange("linkedIn")}
                               className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="phone" className="text-sm font-medium text-gray-700">Phone</label>
                        <input type="text" name="phone" id="phone" value={phone} onChange={inputChange("phone")}
                               onBlur={inputChange("phone")}
                               className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                    </div>
                </div>
            </Modal>

            <aside className="bg-white w-1/4 p-4 border-r overflow-y-auto no-print flex flex-col gap-4">
                <h2 className="text-lg font-bold">Employees</h2>
                <ul>
                    {employees.map((employee) => (
                        <li className="flex items-center gap-2" key={employee.id}>
                            <div className="flex items-center justify-between w-full gap-2">
                                <img
                                    width={60}
                                    height={60}
                                    src={employee.avatarSource}
                                    alt={employee.fullName}
                                    className={"rounded-full"}
                                />
                                <a href="#"
                                   onClick={() => {
                                       setSelectedEmployee(employee);
                                       setSelectedResume(null);
                                   }}
                                   className={`block flex-grow p-2 hover:bg-gray-200 ${selectedEmployee && employee.id === selectedEmployee.id ? 'bg-gray-200' : ''} rounded`}>{employee.fullName}</a>
                            </div>
                            <button className="text-blue-500 hover:underline" onClick={() => {
                                setName(employee.fullName);
                                setEmail(employee.email);
                                setLinkedIn(employee.linkedIn);
                                setPhone(employee.phone);
                                setAvatarSource(employee.avatarSource);
                                setEmployeeId(employee.id);
                                setShowEmployeeModal(true);
                            }}>
                                <MdEdit className="text-black"/>
                            </button>
                        </li>
                    ))}
                </ul>
                <button className="w-full bg-purple-500 text-white p-2 rounded hover:bg-purple-400"
                        onClick={() => setShowEmployeeModal(true)}>Add New
                    Employee
                </button>
            </aside>
        </>
    )
};

export default EmployeesList;