import React from "react";
import nl from "../lang/nl.json";
import en from "../lang/en.json";
import {IntlProvider} from "react-intl";
import {useLocaleStore} from "../stores/LocaleStore";

export default function LanguageSwitcher({ children }: any) {
    // @ts-ignore
    const locale = useLocaleStore(state => state.locale);

    return (
        <IntlProvider locale={locale} messages={locale === 'nl' ? nl : en}>
            {children}
        </IntlProvider>
    )
}