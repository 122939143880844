import React, {useState} from "react";

interface Props {
    title: string;
    children: React.ReactNode;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onSaved: () => void;
    onClosed: () => void;
}

export default function Modal({title, children, show, setShow, onSaved, onClosed}: Props) {
    return (
        <>
            {show ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        {title}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShow(false)}
                                    >
                    <span
                        className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    {children}
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="rounded-full bg-app-gray-900 text-white p-2 pl-5 pr-5 hover:bg-app-gray-600 mr-20"
                                        type="button"
                                        onClick={() => {
                                            setShow(false)
                                            onClosed()
                                        }}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="rounded-full bg-app-green-500 text-black p-2 pl-5 pr-5 hover:bg-app-green-600"
                                        type="button"
                                        onClick={() => {
                                            setShow(false)
                                            onSaved()
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}