import React from "react";
import {
    CreateResumeInput,
    Employee,
    Resume,
    useCreateResumeMutation,
    useDeleteResumeMutation
} from "../../__generated__/graphql";
import {v4 as uuidv4} from "uuid";
import {toast} from "react-toastify";
import {stripTypename} from "@apollo/client/utilities";
import {IoDuplicate} from "react-icons/io5";
import {FaRegTrashCan} from "react-icons/fa6";
import {FaTrash} from "react-icons/fa";

interface ResumesListProps {
    resumes: Resume[] | null,
    selectedEmployee: Employee | null,
    selectedResume: Resume | null,
    refetchListEmployees: () => void,
    setSelectedResume: React.Dispatch<React.SetStateAction<Resume | null>>,
}

const ResumesList = ({
                         resumes,
                         selectedEmployee,
                         selectedResume,
                         refetchListEmployees,
                         setSelectedResume
                     }: ResumesListProps) => {
    const [createResume, {loading}] = useCreateResumeMutation();
    const [deleteResume] = useDeleteResumeMutation();

    const addNewResume = () => {
        createResume({
            variables: {
                input: {
                    availability: {
                        nl: "40 uur per week",
                        en: "40 hours per week"
                    },
                    cicd: ["Foo", "Bar", "Biz", "Baz"],
                    city: {
                        nl: "Amsterdam",
                        en: "Amsterdam"
                    },
                    cloud: ["Foo", "Bar", "Biz", "Baz"],
                    databases: ["Foo", "Bar", "Biz", "Baz"],
                    description: {
                        nl: "Beschrijving over persoon",
                        en: "Description about person"
                    },
                    education: {
                        nl: ["Foo", "Bar", "Biz", "Baz"],
                        en: ["Foo", "Bar", "Biz", "Baz"]
                    },
                    employeeId: selectedEmployee?.id,
                    experiences: {
                        nl: [
                            {
                                title: "Software Engineer (via Tuple) bij ING",
                                achievements: ["Opzetten van een CI/CD pipeline", "Opzetten van een monitoring oplossing", "Opzetten van een logging oplossing"],
                                from: "okt 2019",
                                till: "heden",
                                pageBreak: false,
                            }
                        ],
                        en: [
                            {
                                title: "Software Engineer (via Tuple) at ING",
                                achievements: ["Setting up a CI/CD pipeline", "Setting up a monitoring solution", "Setting up a logging solution"],
                                from: "Oct 2019",
                                till: "present",
                                pageBreak: false,
                            }
                        ]
                    },
                    id: uuidv4(),
                    monitoring: ["Foo", "Bar", "Biz", "Baz"],
                    naturalLanguages: {
                        nl: ["Nederlands", "Engels"],
                        en: ["Dutch", "English"]
                    },
                    otherLanguages: ["Foo", "Bar", "Biz", "Baz"],
                    primaryLanguages: ["Foo", "Bar", "Biz", "Baz"],
                    technologies: ["Foo", "Bar", "Biz", "Baz"],
                    title: "Software Engineer (new resume)",
                    tools: ["Foo", "Bar", "Biz", "Baz"]
                } as CreateResumeInput
            }
        }).then(() => {
            refetchListEmployees();
        }).catch((err: any) => {
            console.error(err)
        });
    }

    const duplicateResume = (resume: Resume) => {
        createResume({
            variables: {
                input: {
                    ...stripTypename(resume),
                    id: uuidv4(),
                    employeeId: selectedEmployee?.id,
                    title: `${resume.title} (copy)`,
                } as CreateResumeInput
            }
        }).then(() => {
            toast.success("Resume duplicated successfully")
            refetchListEmployees();
        }).catch((err: any) => {
            console.error(err)
        });
    }

    return (
        <>
            <aside className="bg-white w-1/4 p-4 border-r overflow-y-auto no-print flex flex-col gap-4">
                <h2 className="text-lg font-bold">Resumes</h2>
                <ul className="flex flex-col gap-2">
                    {resumes?.map((resume) => (
                        <li className="flex items-center justify-between gap-2" key={resume.id}>
                            <div className="flex items-center w-full">
                                <a href="#" onClick={() => setSelectedResume(resume)}
                                   className={`block p-2 flex-grow hover:bg-gray-200 rounded ${selectedResume && resume.id === selectedResume.id ? 'bg-gray-200' : ''}`}>{resume.title}</a>
                            </div>
                            <div className="flex items-center gap-2">
                                <button
                                    className="text-blue-500 hover:underline"
                                    onClick={() => duplicateResume(resume)}>
                                    <IoDuplicate className="text-black"/>
                                </button>
                                <button className="text-blue-500 hover:underline" onClick={() => {
                                    if (window.confirm('Are you sure you want to delete this resume?')) {
                                        deleteResume({
                                            variables: {
                                                input: {
                                                    id: resume.id
                                                }
                                            }
                                        }).then(() => {
                                            refetchListEmployees();
                                            toast.success("Resume deleted successfully")
                                        })
                                    }
                                }}>
                                    <FaTrash className="text-red-500 text-sm"/>
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
                <button onClick={addNewResume} disabled={loading}
                        className="w-full bg-purple-500 text-white p-2 rounded hover:bg-purple-400 disabled:opacity-50">Add
                    New Resume
                </button>
            </aside>
        </>
    )
};

export default ResumesList;