import React from "react";
import {FormattedMessage} from "react-intl";
import sanitizeHtml from "sanitize-html";
import sanitize from "sanitize-html";
import ContentEditable from "react-contenteditable";

interface Props {
    email: string | null;
    phone: string | null;
    city: string  | null;
    setCity: React.Dispatch<React.SetStateAction<string | null>>;
    linkedIn: string | null;
    anonymized: boolean;
}

const Footer = ({email, phone, city, setCity, linkedIn, anonymized}: Props) => {
    const OnChange = (field: string) => (evt: { currentTarget: { innerHTML: any; }; }) => {
        const sanitizeConf: sanitize.IOptions = {
            allowedTags: [],
            disallowedTagsMode: 'discard' as sanitize.DisallowedTagsModes
        };

        const newContent = sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf);

        switch (field) {
            case 'city':
                setCity(newContent);
                break;
        }

        return;
    };

    return <div className="footer bg-app-purple-950 border-t-8 border-app-purple-500 text-sm font-light">
        <div className="flex justify-around text-white px-10 my-5">
            <div className="text-left w-full">
                {!anonymized && <>
                    <ul>
                        <li className="font-medium"><FormattedMessage id={"footer.contact_information"}/></li>
                        <li>{email}</li>
                        <li>{phone}</li>
                    </ul>
                </>}
            </div>
            <div className="text-center w-full">
                <ul>
                    <li>&nbsp;</li>
                    <li><strong><FormattedMessage id={"footer.city"}/>:</strong> <ContentEditable html={city ?? ""}
                                                                                                  className="content-editable inline-block"
                                                                                                  suppressContentEditableWarning={true}
                                                                                                  onChange={OnChange('city')}
                                                                                                  onBlur={OnChange('city')}
                    />
                    </li>
                    {!anonymized &&
                        <li>{linkedIn}</li>
                    }
                </ul>
            </div>
            <div className="text-right w-full text-gray-400">
                <div className="flex justify-end w-full">
                    <img src="/img/logo-full.svg" alt="Tuple Logo" className="h-8 text-white"/>
                </div>
                <ul>
                    <li><a href="mailto:info@tuple.nl" className="underline">info@tuple.nl</a></li>
                    <li><a href="https://www.tuple.nl" className="underline">www.tuple.nl</a></li>
                </ul>
            </div>
        </div>
    </div>;
};

export default Footer;
