import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Availability = {
  __typename?: 'Availability';
  en: Maybe<Scalars['String']['output']>;
  nl: Maybe<Scalars['String']['output']>;
};

export type City = {
  __typename?: 'City';
  en: Maybe<Scalars['String']['output']>;
  nl: Maybe<Scalars['String']['output']>;
};

export type CreateEmployeeInput = {
  avatarSource: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  linkedIn: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateResumeInput = {
  availability: InputMaybe<I18NStringInput>;
  cicd: InputMaybe<Array<Scalars['String']['input']>>;
  city: InputMaybe<I18NStringInput>;
  cloud: InputMaybe<Array<Scalars['String']['input']>>;
  databases: InputMaybe<Array<Scalars['String']['input']>>;
  description: InputMaybe<I18NStringInput>;
  education: InputMaybe<I18NStringListInput>;
  employeeId: Scalars['String']['input'];
  experiences: InputMaybe<ExperiencesInput>;
  id: Scalars['String']['input'];
  monitoring: InputMaybe<Array<Scalars['String']['input']>>;
  naturalLanguages: InputMaybe<I18NStringListInput>;
  otherLanguages: InputMaybe<Array<Scalars['String']['input']>>;
  primaryLanguages: InputMaybe<Array<Scalars['String']['input']>>;
  technologies: InputMaybe<Array<Scalars['String']['input']>>;
  title: InputMaybe<Scalars['String']['input']>;
  tools: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DeleteEmployeeInput = {
  id: Scalars['String']['input'];
};

export type DeleteResumeInput = {
  id: Scalars['String']['input'];
};

export type Description = {
  __typename?: 'Description';
  en: Maybe<Scalars['String']['output']>;
  nl: Maybe<Scalars['String']['output']>;
};

export type Education = {
  __typename?: 'Education';
  en: Maybe<Array<Scalars['String']['output']>>;
  nl: Maybe<Array<Scalars['String']['output']>>;
};

export type Employee = {
  __typename?: 'Employee';
  avatarSource: Scalars['String']['output'];
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  linkedIn: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  resumes: Maybe<Array<Resume>>;
};

export type EmployeeConnection = {
  __typename?: 'EmployeeConnection';
  items: Maybe<Array<Maybe<Employee>>>;
  nextToken: Maybe<Scalars['String']['output']>;
};

export type Experience = {
  __typename?: 'Experience';
  achievements: Maybe<Array<Scalars['String']['output']>>;
  from: Maybe<Scalars['String']['output']>;
  pageBreak: Maybe<Scalars['Boolean']['output']>;
  till: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type ExperienceInput = {
  achievements: Array<Scalars['String']['input']>;
  from: Scalars['String']['input'];
  pageBreak: Scalars['Boolean']['input'];
  till: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type Experiences = {
  __typename?: 'Experiences';
  en: Maybe<Array<Experience>>;
  nl: Maybe<Array<Experience>>;
};

export type ExperiencesInput = {
  en: Array<ExperienceInput>;
  nl: Array<ExperienceInput>;
};

export type I18NStringInput = {
  en: Scalars['String']['input'];
  nl: Scalars['String']['input'];
};

export type I18NStringListInput = {
  en: Array<Scalars['String']['input']>;
  nl: Array<Scalars['String']['input']>;
};

export type ModelSizeInput = {
  between: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq: InputMaybe<Scalars['Int']['input']>;
  ge: InputMaybe<Scalars['Int']['input']>;
  gt: InputMaybe<Scalars['Int']['input']>;
  le: InputMaybe<Scalars['Int']['input']>;
  lt: InputMaybe<Scalars['Int']['input']>;
  ne: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createEmployee: Maybe<Employee>;
  createResume: Maybe<Resume>;
  deleteEmployee: Maybe<Employee>;
  deleteResume: Maybe<Resume>;
  updateEmployee: Maybe<Employee>;
  updateResume: Maybe<Resume>;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationCreateResumeArgs = {
  input: CreateResumeInput;
};


export type MutationDeleteEmployeeArgs = {
  input: DeleteEmployeeInput;
};


export type MutationDeleteResumeArgs = {
  input: DeleteResumeInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationUpdateResumeArgs = {
  input: UpdateResumeInput;
};

export type NaturalLanguages = {
  __typename?: 'NaturalLanguages';
  en: Maybe<Array<Scalars['String']['output']>>;
  nl: Maybe<Array<Scalars['String']['output']>>;
};

export type Query = {
  __typename?: 'Query';
  getEmployee: Maybe<Employee>;
  getResume: Maybe<Resume>;
  listEmployees: Maybe<EmployeeConnection>;
  listResumes: Maybe<ResumeConnection>;
  queryResumesByResumesByEmployee: Maybe<ResumeConnection>;
};


export type QueryGetEmployeeArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetResumeArgs = {
  id: Scalars['String']['input'];
};


export type QueryListEmployeesArgs = {
  filter: InputMaybe<TableEmployeeFilterInput>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextToken: InputMaybe<Scalars['String']['input']>;
};


export type QueryListResumesArgs = {
  filter: InputMaybe<TableResumeFilterInput>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nextToken: InputMaybe<Scalars['String']['input']>;
};


export type QueryQueryResumesByResumesByEmployeeArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['String']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
};

export type Resume = {
  __typename?: 'Resume';
  availability: Maybe<Availability>;
  cicd: Maybe<Array<Scalars['String']['output']>>;
  city: Maybe<City>;
  cloud: Maybe<Array<Scalars['String']['output']>>;
  databases: Maybe<Array<Scalars['String']['output']>>;
  description: Maybe<Description>;
  education: Maybe<Education>;
  employeeId: Maybe<Scalars['String']['output']>;
  experiences: Maybe<Experiences>;
  id: Scalars['String']['output'];
  monitoring: Maybe<Array<Scalars['String']['output']>>;
  naturalLanguages: Maybe<NaturalLanguages>;
  otherLanguages: Maybe<Array<Scalars['String']['output']>>;
  primaryLanguages: Maybe<Array<Scalars['String']['output']>>;
  technologies: Maybe<Array<Scalars['String']['output']>>;
  title: Maybe<Scalars['String']['output']>;
  tools: Maybe<Array<Scalars['String']['output']>>;
};

export type ResumeConnection = {
  __typename?: 'ResumeConnection';
  items: Maybe<Array<Maybe<Resume>>>;
  nextToken: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateEmployee: Maybe<Employee>;
  onCreateResume: Maybe<Resume>;
  onDeleteEmployee: Maybe<Employee>;
  onDeleteResume: Maybe<Resume>;
  onUpdateEmployee: Maybe<Employee>;
  onUpdateResume: Maybe<Resume>;
};


export type SubscriptionOnCreateEmployeeArgs = {
  avatarSource: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreateResumeArgs = {
  cloud: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  employeeId: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  monitoring: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeleteEmployeeArgs = {
  avatarSource: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeleteResumeArgs = {
  cloud: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  employeeId: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  monitoring: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdateEmployeeArgs = {
  avatarSource: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdateResumeArgs = {
  cloud: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  employeeId: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['String']['input']>;
  monitoring: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Scalars['String']['input']>;
};

export type TableBooleanFilterInput = {
  attributeExists: InputMaybe<Scalars['Boolean']['input']>;
  eq: InputMaybe<Scalars['Boolean']['input']>;
  ne: InputMaybe<Scalars['Boolean']['input']>;
};

export type TableEmployeeFilterInput = {
  avatarSource: InputMaybe<TableStringFilterInput>;
  id: InputMaybe<TableStringFilterInput>;
};

export type TableFloatFilterInput = {
  attributeExists: InputMaybe<Scalars['Boolean']['input']>;
  between: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  eq: InputMaybe<Scalars['Float']['input']>;
  ge: InputMaybe<Scalars['Float']['input']>;
  gt: InputMaybe<Scalars['Float']['input']>;
  le: InputMaybe<Scalars['Float']['input']>;
  lt: InputMaybe<Scalars['Float']['input']>;
  ne: InputMaybe<Scalars['Float']['input']>;
};

export type TableIdFilterInput = {
  attributeExists: InputMaybe<Scalars['Boolean']['input']>;
  beginsWith: InputMaybe<Scalars['ID']['input']>;
  between: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains: InputMaybe<Scalars['ID']['input']>;
  eq: InputMaybe<Scalars['ID']['input']>;
  ge: InputMaybe<Scalars['ID']['input']>;
  gt: InputMaybe<Scalars['ID']['input']>;
  le: InputMaybe<Scalars['ID']['input']>;
  lt: InputMaybe<Scalars['ID']['input']>;
  ne: InputMaybe<Scalars['ID']['input']>;
  notContains: InputMaybe<Scalars['ID']['input']>;
  size: InputMaybe<ModelSizeInput>;
};

export type TableIntFilterInput = {
  attributeExists: InputMaybe<Scalars['Boolean']['input']>;
  between: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq: InputMaybe<Scalars['Int']['input']>;
  ge: InputMaybe<Scalars['Int']['input']>;
  gt: InputMaybe<Scalars['Int']['input']>;
  le: InputMaybe<Scalars['Int']['input']>;
  lt: InputMaybe<Scalars['Int']['input']>;
  ne: InputMaybe<Scalars['Int']['input']>;
};

export type TableResumeFilterInput = {
  cicd: InputMaybe<TableStringFilterInput>;
  cloud: InputMaybe<TableStringFilterInput>;
  databases: InputMaybe<TableStringFilterInput>;
  employeeId: InputMaybe<TableStringFilterInput>;
  id: InputMaybe<TableStringFilterInput>;
  monitoring: InputMaybe<TableStringFilterInput>;
  naturalLanguages: InputMaybe<TableStringFilterInput>;
  otherLanguages: InputMaybe<TableStringFilterInput>;
  primaryLanguages: InputMaybe<TableStringFilterInput>;
  technologies: InputMaybe<TableStringFilterInput>;
  title: InputMaybe<TableStringFilterInput>;
  tools: InputMaybe<TableStringFilterInput>;
};

export type TableStringFilterInput = {
  attributeExists: InputMaybe<Scalars['Boolean']['input']>;
  beginsWith: InputMaybe<Scalars['String']['input']>;
  between: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains: InputMaybe<Scalars['String']['input']>;
  eq: InputMaybe<Scalars['String']['input']>;
  ge: InputMaybe<Scalars['String']['input']>;
  gt: InputMaybe<Scalars['String']['input']>;
  le: InputMaybe<Scalars['String']['input']>;
  lt: InputMaybe<Scalars['String']['input']>;
  ne: InputMaybe<Scalars['String']['input']>;
  notContains: InputMaybe<Scalars['String']['input']>;
  size: InputMaybe<ModelSizeInput>;
};

export type UpdateEmployeeInput = {
  avatarSource: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  linkedIn: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type UpdateResumeInput = {
  availability: InputMaybe<I18NStringInput>;
  cicd: InputMaybe<Array<Scalars['String']['input']>>;
  city: InputMaybe<I18NStringInput>;
  cloud: InputMaybe<Array<Scalars['String']['input']>>;
  databases: InputMaybe<Array<Scalars['String']['input']>>;
  description: InputMaybe<I18NStringInput>;
  education: InputMaybe<I18NStringListInput>;
  employeeId: InputMaybe<Scalars['String']['input']>;
  experiences: InputMaybe<ExperiencesInput>;
  id: Scalars['String']['input'];
  monitoring: InputMaybe<Array<Scalars['String']['input']>>;
  naturalLanguages: InputMaybe<I18NStringListInput>;
  otherLanguages: InputMaybe<Array<Scalars['String']['input']>>;
  primaryLanguages: InputMaybe<Array<Scalars['String']['input']>>;
  technologies: InputMaybe<Array<Scalars['String']['input']>>;
  title: InputMaybe<Scalars['String']['input']>;
  tools: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateEmployeeMutationVariables = Exact<{
  input: CreateEmployeeInput;
}>;


export type CreateEmployeeMutation = { __typename?: 'Mutation', createEmployee: { __typename?: 'Employee', id: string } | null };

export type CreateResumeMutationVariables = Exact<{
  input: CreateResumeInput;
}>;


export type CreateResumeMutation = { __typename?: 'Mutation', createResume: { __typename?: 'Resume', id: string } | null };

export type DeleteResumeMutationVariables = Exact<{
  input: DeleteResumeInput;
}>;


export type DeleteResumeMutation = { __typename?: 'Mutation', deleteResume: { __typename?: 'Resume', id: string } | null };

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'Employee', id: string } | null };

export type UpdateResumeMutationVariables = Exact<{
  input: UpdateResumeInput;
}>;


export type UpdateResumeMutation = { __typename?: 'Mutation', updateResume: { __typename?: 'Resume', id: string } | null };

export type ListEmployeesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListEmployeesQuery = { __typename?: 'Query', listEmployees: { __typename?: 'EmployeeConnection', items: Array<{ __typename?: 'Employee', id: string, email: string, fullName: string, linkedIn: string, avatarSource: string, phone: string, resumes: Array<{ __typename?: 'Resume', title: string | null, cicd: Array<string> | null, cloud: Array<string> | null, databases: Array<string> | null, id: string, monitoring: Array<string> | null, otherLanguages: Array<string> | null, primaryLanguages: Array<string> | null, technologies: Array<string> | null, tools: Array<string> | null, experiences: { __typename?: 'Experiences', en: Array<{ __typename?: 'Experience', achievements: Array<string> | null, from: string | null, pageBreak: boolean | null, till: string | null, title: string | null }> | null, nl: Array<{ __typename?: 'Experience', achievements: Array<string> | null, from: string | null, pageBreak: boolean | null, till: string | null, title: string | null }> | null } | null, availability: { __typename?: 'Availability', nl: string | null, en: string | null } | null, city: { __typename?: 'City', en: string | null, nl: string | null } | null, description: { __typename?: 'Description', en: string | null, nl: string | null } | null, education: { __typename?: 'Education', en: Array<string> | null, nl: Array<string> | null } | null, naturalLanguages: { __typename?: 'NaturalLanguages', en: Array<string> | null, nl: Array<string> | null } | null }> | null } | null> | null } | null };


export const CreateEmployeeDocument = gql`
    mutation createEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    id
  }
}
    `;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export type CreateEmployeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>, 'mutation'>;

    export const CreateEmployeeComponent = (props: CreateEmployeeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEmployeeMutation, CreateEmployeeMutationVariables> mutation={CreateEmployeeDocument} {...props} />
    );
    

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, options);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const CreateResumeDocument = gql`
    mutation createResume($input: CreateResumeInput!) {
  createResume(input: $input) {
    id
  }
}
    `;
export type CreateResumeMutationFn = Apollo.MutationFunction<CreateResumeMutation, CreateResumeMutationVariables>;
export type CreateResumeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateResumeMutation, CreateResumeMutationVariables>, 'mutation'>;

    export const CreateResumeComponent = (props: CreateResumeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateResumeMutation, CreateResumeMutationVariables> mutation={CreateResumeDocument} {...props} />
    );
    

/**
 * __useCreateResumeMutation__
 *
 * To run a mutation, you first call `useCreateResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResumeMutation, { data, loading, error }] = useCreateResumeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResumeMutation(baseOptions?: Apollo.MutationHookOptions<CreateResumeMutation, CreateResumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResumeMutation, CreateResumeMutationVariables>(CreateResumeDocument, options);
      }
export type CreateResumeMutationHookResult = ReturnType<typeof useCreateResumeMutation>;
export type CreateResumeMutationResult = Apollo.MutationResult<CreateResumeMutation>;
export type CreateResumeMutationOptions = Apollo.BaseMutationOptions<CreateResumeMutation, CreateResumeMutationVariables>;
export const DeleteResumeDocument = gql`
    mutation deleteResume($input: DeleteResumeInput!) {
  deleteResume(input: $input) {
    id
  }
}
    `;
export type DeleteResumeMutationFn = Apollo.MutationFunction<DeleteResumeMutation, DeleteResumeMutationVariables>;
export type DeleteResumeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteResumeMutation, DeleteResumeMutationVariables>, 'mutation'>;

    export const DeleteResumeComponent = (props: DeleteResumeComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteResumeMutation, DeleteResumeMutationVariables> mutation={DeleteResumeDocument} {...props} />
    );
    

/**
 * __useDeleteResumeMutation__
 *
 * To run a mutation, you first call `useDeleteResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResumeMutation, { data, loading, error }] = useDeleteResumeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResumeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResumeMutation, DeleteResumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResumeMutation, DeleteResumeMutationVariables>(DeleteResumeDocument, options);
      }
export type DeleteResumeMutationHookResult = ReturnType<typeof useDeleteResumeMutation>;
export type DeleteResumeMutationResult = Apollo.MutationResult<DeleteResumeMutation>;
export type DeleteResumeMutationOptions = Apollo.BaseMutationOptions<DeleteResumeMutation, DeleteResumeMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation updateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    id
  }
}
    `;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export type UpdateEmployeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>, 'mutation'>;

    export const UpdateEmployeeComponent = (props: UpdateEmployeeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables> mutation={UpdateEmployeeDocument} {...props} />
    );
    

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const UpdateResumeDocument = gql`
    mutation updateResume($input: UpdateResumeInput!) {
  updateResume(input: $input) {
    id
  }
}
    `;
export type UpdateResumeMutationFn = Apollo.MutationFunction<UpdateResumeMutation, UpdateResumeMutationVariables>;
export type UpdateResumeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateResumeMutation, UpdateResumeMutationVariables>, 'mutation'>;

    export const UpdateResumeComponent = (props: UpdateResumeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateResumeMutation, UpdateResumeMutationVariables> mutation={UpdateResumeDocument} {...props} />
    );
    

/**
 * __useUpdateResumeMutation__
 *
 * To run a mutation, you first call `useUpdateResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResumeMutation, { data, loading, error }] = useUpdateResumeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResumeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResumeMutation, UpdateResumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResumeMutation, UpdateResumeMutationVariables>(UpdateResumeDocument, options);
      }
export type UpdateResumeMutationHookResult = ReturnType<typeof useUpdateResumeMutation>;
export type UpdateResumeMutationResult = Apollo.MutationResult<UpdateResumeMutation>;
export type UpdateResumeMutationOptions = Apollo.BaseMutationOptions<UpdateResumeMutation, UpdateResumeMutationVariables>;
export const ListEmployeesDocument = gql`
    query listEmployees {
  listEmployees {
    items {
      id
      email
      fullName
      linkedIn
      avatarSource
      phone
      resumes {
        title
        experiences {
          en {
            achievements
            from
            pageBreak
            till
            title
          }
          nl {
            achievements
            from
            pageBreak
            till
            title
          }
        }
        availability {
          nl
          en
        }
        cicd
        city {
          en
          nl
        }
        cloud
        databases
        description {
          en
          nl
        }
        education {
          en
          nl
        }
        id
        monitoring
        otherLanguages
        primaryLanguages
        technologies
        naturalLanguages {
          en
          nl
        }
        tools
      }
    }
  }
}
    `;
export type ListEmployeesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListEmployeesQuery, ListEmployeesQueryVariables>, 'query'>;

    export const ListEmployeesComponent = (props: ListEmployeesComponentProps) => (
      <ApolloReactComponents.Query<ListEmployeesQuery, ListEmployeesQueryVariables> query={ListEmployeesDocument} {...props} />
    );
    

/**
 * __useListEmployeesQuery__
 *
 * To run a query within a React component, call `useListEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
      }
export function useListEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export function useListEmployeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export type ListEmployeesQueryHookResult = ReturnType<typeof useListEmployeesQuery>;
export type ListEmployeesLazyQueryHookResult = ReturnType<typeof useListEmployeesLazyQuery>;
export type ListEmployeesSuspenseQueryHookResult = ReturnType<typeof useListEmployeesSuspenseQuery>;
export type ListEmployeesQueryResult = Apollo.QueryResult<ListEmployeesQuery, ListEmployeesQueryVariables>;