import React from "react";
import './Experiences.css';
import {FormattedMessage} from "react-intl";
import sanitizeHtml from "sanitize-html";
import sanitize from "sanitize-html";
import ContentEditable from "react-contenteditable";
import {MdInsertPageBreak} from "react-icons/md";
import {BiSolidChevronDownCircle, BiSolidChevronUpCircle} from "react-icons/bi";
import {IoMdRemoveCircle} from "react-icons/io";
import {Experience} from "../../__generated__/graphql";

interface Props {
    experiences: Experience[] | null;
    setExperiences: React.Dispatch<React.SetStateAction<Experience[] | null>>;
}

const Experiences = ({experiences, setExperiences}: Props) => {
    const onChange = (field: keyof Experience, idx: number) => (evt: { currentTarget: { innerHTML: any; }; }) => {
        if (!experiences) return;

        const sanitizeConf: sanitize.IOptions = {
            allowedTags: [field === 'achievements' ? 'ul' : 'ul', 'li', 'ol', 'br', 'b', 'strong'],
            disallowedTagsMode: 'discard' as sanitize.DisallowedTagsModes
        };

        let copy = [...experiences];

        // In case we need to field is achievements
        const newContent = sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf);
        const updatedValue =
            field === 'achievements'
                ? newContent.split('<li>').map((i) => i.replace('</li>', '')).filter((i) => i.length > 0)
                : newContent;

        copy[idx] = {
            ...copy[idx],
            [field]: updatedValue
        };

        setExperiences(copy);
    };

    // nani umayma

    const togglePageBreak = (idx: number) => () => {
        const copy = experiences ? JSON.parse(JSON.stringify(experiences)) : [];
        if (copy[idx]) {
            copy[idx] = {...copy[idx], pageBreak: !copy[idx].pageBreak};
        }
        setExperiences(copy);
    }


    const achievementsMapped = (e: Experience) => e.achievements?.map((a, idx) => {
        return `<li class="list-disc">${a}</li>`
    }).join('');

    const addNewExperienceItem = () => {
        const copy = experiences ? JSON.parse(JSON.stringify(experiences)) : []
        copy.unshift({
            title: 'New Experience',
            from: `Jan ${new Date().getFullYear()}`,
            till: `Dec ${new Date().getFullYear()}`,
            achievements: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            ],
            pageBreak: false
        })

        setExperiences(copy);
    }

    return <div>
        <h3 className="text-xl font-medium mb-2"><FormattedMessage id={"experience"}/></h3>
        {experiences && experiences.map((e, idx) => {
            return <div
                key={idx}
                className={`border-l-2 border-app-purple-200 pb-2 pt-4 ${e.pageBreak ? 'pageBreak' : ''}`}
            >
                {e.pageBreak && (
                    <MdInsertPageBreak className="absolute mt-1 -ml-8 text-app-purple-700 no-print"/>
                )}
                <ul className={`${idx > 0 && experiences[idx - 1].pageBreak ? 'print:pt-10' : ''}`} key={idx}>
                    <li className="font-medium -mb-6" key={idx + '_' + idx}>
                        <div className="flex items-center justify-between">
                            <div className="flex">

                                <div
                                    className="flex flex-col gap-0.5"
                                    style={{marginLeft: "-8.8px", marginTop: "-13px"}}
                                >
                                    <BiSolidChevronUpCircle
                                        className={`no-print-invisible ${idx > 0 ? 'cursor-pointer text-purple-400' : 'text-neutral-400'} `}
                                        onClick={() => {
                                            if (idx > 0) {
                                                const copy = [...experiences];
                                                const temp = copy[idx - 1];
                                                copy[idx - 1] = copy[idx];
                                                copy[idx] = temp;
                                                setExperiences(copy);
                                            }
                                        }}
                                    />

                                    <img src="/circle.svg" alt="Dot"
                                         onClick={togglePageBreak(idx)}
                                         className={`w-4 mr-2 content-editable`}
                                    />

                                    <BiSolidChevronDownCircle
                                        className={`no-print-invisible ${idx < experiences.length - 1 ? 'cursor-pointer text-purple-400' : 'text-neutral-400'}`}
                                        onClick={() => {
                                            if (idx < experiences.length - 1) {
                                                const copy = [...experiences];
                                                const temp = copy[idx + 1];
                                                copy[idx + 1] = copy[idx];
                                                copy[idx] = temp;
                                                setExperiences(copy);
                                            }
                                        }}
                                    />

                                    <IoMdRemoveCircle
                                        className={`cursor-pointer no-print-invisible text-red-500`}
                                        onClick={() => {
                                            const copy = [...experiences];
                                            copy.splice(idx, 1);
                                            setExperiences(copy);
                                        }}
                                    />
                                </div>

                                <ContentEditable
                                    html={e.title ?? ""}
                                    className="content-editable"
                                    suppressContentEditableWarning={true}
                                    onChange={onChange('title', idx)}
                                />
                            </div>
                            <div className="font-normal text-gray-500 mb-8">
                                <ContentEditable
                                    html={e.from ?? ""}
                                    className="content-editable inline-block"
                                    suppressContentEditableWarning={true}
                                    onChange={onChange('from', idx)}
                                />
                                {' - '}
                                <ContentEditable
                                    html={e.till ?? ""}
                                    className="content-editable inline-block"
                                    suppressContentEditableWarning={true}
                                    onChange={onChange('till', idx)}
                                />
                            </div>
                        </div>
                    </li>
                    <li>
                        <ul className="ml-2">
                            <ContentEditable
                                className="ml-8 content-editable"
                                suppressContentEditableWarning={true}
                                html={achievementsMapped(e) ?? ""}
                                onChange={onChange('achievements', idx)}
                                tagName={'ul'}
                            />
                        </ul>
                    </li>
                </ul>
            </div>
        })}
        <button
            className="mt-5 rounded-full bg-app-gray-900 text-white p-2 pl-5 pr-5 hover:bg-app-gray-600 no-print"
            onClick={addNewExperienceItem}>
            Add new experience
        </button>
    </div>
};

export default Experiences;
