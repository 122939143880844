import React, {useEffect, useState} from 'react';
import './Resume.css';
import Footer from "./components/Footer";
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import Overview from "./components/Overview/Overview";
import AvailabilityAndEducation from "./components/AvailabilityAndEducation";
import ExperiencesComponent from "./components/Experiences/Experiences";
import AboutTuple from "./components/AboutTuple";
import {
    Availability,
    City,
    Description,
    Education,
    Employee,
    Experience,
    Experiences,
    NaturalLanguages,
    Resume,
    UpdateResumeInput,
    useUpdateResumeMutation
} from "./__generated__/graphql";
import {useLocaleStore} from "./stores/LocaleStore";
import {stripTypename} from "@apollo/client/utilities";
import {toast} from "react-toastify";


interface ResumeProps {
    resume: Resume;
    employee: Employee;
    refetchListEmployees: () => void,
}

const ResumeEditor = ({
                          employee,
                          resume: selectedResume,
                          refetchListEmployees
                      }: ResumeProps) => {

    const [language, setLanguage] = useState<string>("nl");
    const [anonymized, setAnonymized] = useState<boolean>(false);
    const [showAboutTuple, setShowAboutTuple] = useState<boolean>(true);

    // @ts-ignore
    const locale = useLocaleStore(state => state.locale) as "nl" | "en";
    // @ts-ignore
    const setLocale = useLocaleStore(state => state.setLocale);

    const onShowAboutTupleChange = (e: { target: { value: any; }; }) => {
        setShowAboutTuple(e.target.value !== "0")
    }

    const onLanguageChange = (e: { target: { value: any; }; }) => {
        setLanguage(e.target.value)
        setLocale(e.target.value);
    }

    const onAnonymizedChange = (e: { target: { value: any; }; }) => {
        setAnonymized(e.target.value !== "0")
    }

    const [updateResume] = useUpdateResumeMutation()

    const saveResume = (callback: () => void) => {
        updateResume({
            variables: {
                input: stripTypename(selectedResume) as UpdateResumeInput
            }
        }).then(() => {
            refetchListEmployees()
            callback()
        }).catch((err: any) => {
            console.error(err)
            toast.error('Something went wrong, please try again later.')
        })
    }

    // @ts-ignore
    // const [resume, setResume] = useState<Resume>(selectedResume);
    const [description, setDescription] = useState<string | null>(selectedResume.description ? selectedResume.description[locale] : null);
    const [title, setTitle] = useState<string | null>(selectedResume.title);
    const [primaryLanguages, setPrimaryLanguages] = useState<string[] | null>(selectedResume.primaryLanguages);
    const [otherLanguages, setOtherLanguages] = useState<string[] | null>(selectedResume.otherLanguages);
    const [naturalLanguages, setNaturalLanguages] = useState<string[] | null>(selectedResume.naturalLanguages ? selectedResume.naturalLanguages[locale] : []);
    const [technologies, setTechnologies] = useState<string[] | null>(selectedResume.technologies);
    const [tools, setTools] = useState<string[] | null>(selectedResume.tools);
    const [cicd, setCicd] = useState<string[] | null>(selectedResume.cicd);
    const [databases, setDatabases] = useState<string[] | null>(selectedResume.databases);
    const [cloud, setCloud] = useState<string[] | null>(selectedResume.cloud);
    const [monitoring, setMonitoring] = useState<string[] | null>(selectedResume.monitoring);
    const [availability, setAvailability] = useState<string | null>(selectedResume.availability ? selectedResume.availability[locale] : null);
    const [education, setEducation] = useState<string[] | null>(selectedResume.education ? selectedResume.education[locale] : []);
    const [experiences, setExperiences] = useState<Experience[] | null>(selectedResume.experiences ? selectedResume.experiences[locale] : []);
    const [city, setCity] = useState<string | null>(selectedResume.city ? selectedResume.city[locale] : "");

    useEffect(() => {
        setDescription(selectedResume.description ? selectedResume.description[locale] : null);
        setTitle(selectedResume.title);
        setPrimaryLanguages(selectedResume.primaryLanguages);
        setOtherLanguages(selectedResume.otherLanguages);
        setNaturalLanguages(selectedResume.naturalLanguages ? selectedResume.naturalLanguages[locale] : []);
        setTechnologies(selectedResume.technologies);
        setTools(selectedResume.tools);
        setCicd(selectedResume.cicd);
        setDatabases(selectedResume.databases);
        setCloud(selectedResume.cloud);
        setMonitoring(selectedResume.monitoring);
        setAvailability(selectedResume.availability ? selectedResume.availability[locale] : null);
        setEducation(selectedResume.education ? selectedResume.education[locale] : []);
        setExperiences(selectedResume.experiences ? selectedResume.experiences[locale] : []);
        setCity(selectedResume.city ? selectedResume.city[locale] : "");
    }, [locale, selectedResume]);

    useEffect(() => {
        let newResume = {
            ...selectedResume,
            title,
            primaryLanguages,
            otherLanguages,
            naturalLanguages,
            technologies,
            tools,
            cicd,
            databases,
            cloud,
            monitoring,
            availability,
            education,
        } as Resume

        if (description) {
            newResume.description = {
                ...selectedResume.description,
                [locale]: description
            } as Description
        }

        if (naturalLanguages) {
            newResume.naturalLanguages = {
                ...selectedResume.naturalLanguages,
                [locale]: naturalLanguages
            } as NaturalLanguages
        }

        if (availability) {
            newResume.availability = {
                ...selectedResume.availability,
                [locale]: availability
            } as Availability
        }

        if (education) {
            newResume.education = {
                ...selectedResume.education,
                [locale]: education
            } as Education
        }

        if (experiences) {
            newResume.experiences = {
                ...selectedResume.experiences,
                [locale]: experiences
            } as Experiences
        }

        if (city) {
            newResume.city = {
                ...selectedResume.city,
                [locale]: city
            } as City
        }

        selectedResume = newResume
    }, [description, title, primaryLanguages, otherLanguages, naturalLanguages, technologies, tools, cicd, databases, cloud, monitoring, availability, education, experiences, city])

    const copyFromOtherLanguage = () => {
        if (window.confirm("Are you sure you want to copy from the other language?")) {
            const otherLocale = locale === "nl" ? "en" : "nl";

            setDescription(selectedResume.description ? selectedResume.description[otherLocale] : null);
            setNaturalLanguages(selectedResume.naturalLanguages ? selectedResume.naturalLanguages[otherLocale] : []);
            setAvailability(selectedResume.availability ? selectedResume.availability[otherLocale] : null);
            setEducation(selectedResume.education ? selectedResume.education[otherLocale] : []);
            setExperiences(selectedResume.experiences ? selectedResume.experiences[otherLocale] : []);
            setCity(selectedResume.city ? selectedResume.city[otherLocale] : "");
        }
    }

    const footer = <Footer
        email={employee.email}
        phone={employee.phone}
        setCity={setCity}
        city={city}
        linkedIn={employee.linkedIn}
        anonymized={anonymized}
    />;

    const pages = (
        <>
            <div className="page">
                <Header
                    name={employee.fullName}
                    title={title}
                    setTitle={setTitle}
                    avatarSource={employee.avatarSource}
                    // setAvatarSource={setAvatarSource}
                    anonymized={anonymized}
                />
                <div className="px-10 py-10">
                    <AboutMe
                        description={description}
                        update={setDescription}
                    />
                    <Overview
                        primaryLanguages={primaryLanguages}
                        setPrimaryLanguages={setPrimaryLanguages}
                        otherLanguages={otherLanguages}
                        setOtherLanguages={setOtherLanguages}
                        naturalLanguages={naturalLanguages}
                        setNaturalLanguages={setNaturalLanguages}
                        technologies={technologies}
                        setTechnologies={setTechnologies}
                        tools={tools}
                        setTools={setTools}
                        cicd={cicd}
                        setCicd={setCicd}
                        databases={databases}
                        setDatabases={setDatabases}
                        cloud={cloud}
                        setCloud={setCloud}
                        monitoring={monitoring}
                        setMonitoring={setMonitoring}
                    />
                    <AvailabilityAndEducation
                        availability={availability}
                        setAvailability={setAvailability}
                        education={education}
                        setEducation={setEducation}
                    />
                </div>
                {footer}
            </div>

            <div className="page">
                <div className="p-10">
                    <ExperiencesComponent
                        experiences={experiences}
                        setExperiences={setExperiences}
                    />

                    {showAboutTuple && (
                        <AboutTuple/>
                    )}
                </div>
                {footer}
            </div>
        </>
    )

    return (
        <>
            <main className="bg-white page-container border-r">
                {pages}
            </main>

            <aside className="bg-white w-1/4 p-4 overflow-y-auto no-print flex flex-col gap-4">
                <h2 className="text-lg font-bold">Options</h2>
                <div>
                    <label className="block text-gray-700">Language</label>
                    <div className="flex items-center">
                        <input type="radio" id="language-nl" checked={language === 'nl'} onChange={onLanguageChange}
                               name="language" value="nl"
                               className="mr-2"/>
                        <label htmlFor="language-nl" className="mr-4">Dutch</label>
                        <input type="radio" id="language-en" name="language" value="en" checked={language === 'en'}
                               onChange={onLanguageChange}
                               className="mr-2"/>
                        <label htmlFor="language-en">English</label>
                    </div>
                </div>
                <div>
                    <label className="block text-gray-700">Anonymized</label>
                    <div className="flex items-center">
                        <input type="radio" id="anonymized-yes" checked={anonymized} onChange={onAnonymizedChange}
                               name="anonymized" value="1"
                               className="mr-2"/>
                        <label htmlFor="anonymized-yes" className="mr-4">Yes</label>
                        <input type="radio" id="anonymized-no" name="anonymized" value="0" checked={!anonymized}
                               onChange={onAnonymizedChange}
                               className="mr-2"/>
                        <label htmlFor="anonymized-no">No</label>
                    </div>
                </div>
                <div>
                    <label className="block text-gray-700">Show About Tuple Section</label>
                    <div className="flex items-center">
                        <input type="radio" id="show-about-yes" name="show-about" value="1" checked={showAboutTuple}
                               onChange={onShowAboutTupleChange}
                               className="mr-2"/>
                        <label htmlFor="show-about-yes" className="mr-4">Yes</label>
                        <input type="radio" id="show-about-no" name="show-about" value="0" checked={!showAboutTuple}
                               onChange={onShowAboutTupleChange}
                               className="mr-2"/>
                        <label htmlFor="show-about-no">No</label>
                    </div>
                </div>
                <div className="flex items-center justify-between gap-2">
                    <button
                        className="w-full bg-app-green-500 text-black p-2 rounded hover:bg-app-green-300 whitespace-nowrap"
                        onClick={() => copyFromOtherLanguage()}>
                        Copy from {language === "nl" ? "English" : "Dutch"}
                    </button>
                    <button className="w-full bg-purple-500 text-white p-2 rounded hover:bg-purple-400" onClick={() => {
                        saveResume(() => {
                            toast.success("Resume saved successfully");
                        })
                    }}>Save Resume
                    </button>
                </div>
            </aside>
        </>
    );
}

export default ResumeEditor;
